<template>

  <!-- 密码输入对话框 -->
  <v-dialog v-model="passwordDialog" persistent max-width="300px">
    <v-card>
      <v-card-title>请输入密码</v-card-title>
      <v-card-text>
        <v-text-field
          type="password"
          v-model="password"
          label="密码"
          required
        ></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="passwordDialog = false">取消</v-btn>
        <v-btn color="primary" @click="verifyPassword">验证</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <div v-show="hasAccess">
  <v-container fluid class="px-2" style="height: 100vh;">
    <v-row no-gutters>
      <v-col cols="12" md="12" class="mx-auto full-height-card" style="height: 100%;">
        <v-card style="height: 100%;" class="elevation-12">
          <v-card-title>
            <h1 class="font-weight-bold mb-2">石墨豆腐汤会员管理系统</h1>
          </v-card-title>

          <v-card-text class="text-center">
            <!-- <v-btn @click="addMember" color="success" dark large class="mt-5 me-5">
              添加会员
            </v-btn> -->
            <v-btn @click="listMembers" color="primary" dark large class="mt-5 me-5">
              刷新列表
            </v-btn>
            <v-btn @click="toggleCamera" color="primary" dark large class="mt-5">
              {{ cameraActive ? '关闭摄像头' : '打开摄像头扫码' }}
            </v-btn>
          </v-card-text>

          <v-card v-if="cameraActive" class="camera-container">
            <v-card-text>
              <div id="scanner" class="qr-scanner"></div>
            </v-card-text>
          </v-card>
          
          <!-- 搜索框 -->
          <v-text-field
            v-model="search"
            label="按照姓名或者电话号进行搜索"
            single-line
            hide-details
          >
          <template v-slot:prepend-inner>
            <v-icon>
              <img :src="require('@/assets/search.svg')" />
            </v-icon>
          </template>
          </v-text-field>

            <v-data-table
              :items="filteredMembers"
              :headers="memberHeaders"
              class="elevation-1 mt-0"
              dense
            >
            <template v-slot:item="{ item }">
              <tr>
                <!-- <td>{{ item.guestId }}</td> -->
                <td style="padding: 5px;">{{ item.name }}</td>
                <td style="max-width:50px; padding: 0;">{{ item.phone.replace(/^(\d{3})\d*(\d{4})$/, '$1****$2') }}</td>
                <td style="padding: 0; margin-left: 5px;">
                  剩 <span style="color: red;">{{ item.cnt }} </span> 次
                </td>

                <td style="padding: 0; margin: 0">
                  <v-switch v-model="item.active" style="margin-top: 15px; "
                    :label="item.active ? '已激活' : '未激活'"
                    color="primary" dark depressed small class="ml-10"
                    hide-details
                    @change="toggleMemberStatus(item.guestId)"
                  >
                  </v-switch>
                </td>
                <td>
                  <v-btn style=" height: 40px; font-size: 14px;" @click="checkDetail(item.guestId)" color="primary" dark depressed class="ml-3">
                    详情
                  </v-btn>
                  <v-btn @click="deleteMember(item.guestId)" color="error" dark depressed small class="ml-3">
                    删除
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <!-- 会员信息弹出框 -->
    <v-dialog v-model="showModal" persistent  max-width="400px" no-click-animation no-focus>
      <v-card style="height: auto;" >
        <v-card-title  :class="{ 'bg-green': isRemainingCntPositive, 'bg-red': !isRemainingCntPositive, 'bg-primary': !isAuth}">
          <span class="headline">{{ isAuth ? '验证结果' : '会员信息' }}</span>
        </v-card-title>
        <v-card-text class="text-center">
          <v-container>
            <v-row dense align="center" justify="center" class="mt-0">
              <v-col cols="12">
                <div v-if="isAuth" class="icon-container">
                   <!-- <span style="color: green; font-size: 48px; font-weight: bold;">{{ memberInfo.cnt > 0 ? '消费成功' : '无可用次数' }}</span> -->
                  <!-- <span style="font-size: 24px; font-weight: bold;"> 次 </span> -->
                  <div v-if="memberInfo.success" class="icon checkmark"></div>
                  <div v-if="!memberInfo.success" class="icon crossmark"></div>
                </div>
              </v-col>
            </v-row>
            <v-row dense class="mt-0">
              <v-col cols="12"><v-text-field :readonly="isAuth" dense label="姓名" variant="underlined"  class="text-field-custom mt-0" v-model="memberInfo.name"></v-text-field></v-col>
            </v-row>
            <v-row dense class="mt-0">
              <v-col cols="12"><v-text-field :readonly="isAuth" dense label="手机号"  variant="underlined" class="text-field-custom mt-0" v-model="memberInfo.phone"></v-text-field></v-col>
            </v-row>

            <v-row v-if="!isAuth" class="mt-0">
              <v-col cols="12">          
                <v-text-field
                  dense
                  class="text-field-custom mt-0"
                  label="注册时间"
                  v-model="memberInfo.registerTime"
                  readonly
                  variant="underlined"
                >
                </v-text-field>
              </v-col>
            </v-row>

              <!-- <v-row justify="space-between" align="center" class="mt-0" style="margin-top: 50%;"> -->
            <v-row class="mt-0">
              <v-col class="text-center" cols="3">
                <v-btn @click="decrement" :disabled="memberInfo.cnt <= 0" color="grey lighten-2" text>
                  -10次
                </v-btn>
              </v-col>
              <v-col cols="6" v-if="!isAuth">
                <v-text-field
                  v-model.number="memberInfo.cnt"
                  style="margin-left: 13px; height: 5px; max-height: 5px; "
                  type="number"
                  min="0"
                  max="100"
                  class="text-center"
                  label="剩余可用次数"
                  :disabled="isAuth"
                  :rules="[v => v >= 0 || '至少为0', v => v <= 100 || '最多为100']"
                ></v-text-field>
              </v-col>
              <v-col class="text-center" cols="3">
                <v-btn @click="increment" :disabled="memberInfo.cnt >= 100" color="grey lighten-2" text>
                  +10次
                </v-btn>
              </v-col>
            <!-- </v-row> -->
          </v-row>
                <br/>
                <!-- <span style="margin-left: 10px; font-size: 13px;">注册方式：<span style="color: grey;">{{ memberInfo.registerType == 0 ? '微信小程序' : '管理员手动添加' }}</span></span>
              <v-col cols="12"> -->
                <!-- TODO: 设置次数  -->

              <!-- </v-col> -->
              <!-- 添加一个滚动列表 -->
               <v-row class="mt-5 ml-2">
                <br/>
               <span v-if="!isAuth && memberInfo.authRecords.length" style="margin-left: 0px; font-size: 13px;">验证记录：</span> 
                
                <v-data-table
                  style="max-height: 200px; max-width: 400px; margin-left: 0px;"
                  v-if="!isAuth && memberInfo.authRecords.length"
                  :headers="authRecordHeaders"
                  :items="memberInfo.authRecords"
                  hide-default-footer
                  hide-default-header
                  title="扫码记录"
                  class="elevation-0 mt-0 auth-table ml-0"
                  dense
                >
                  <template v-slot:header="{ headers }">
                    <tr>
                      <th v-for="header in headers" :key="header.value" class="text-overline">
                        {{ header.text }}
                      </th>
                    </tr>
                  </template>
                  
                  <template v-slot:item="{ item }">
                    <tr>
                      <td>{{ item.authTime }}</td>
                      <td>
                        <div v-if="item.success" class="icon small-checkmark"></div>
                        <div v-if="!item.success" class="icon small-crossmark"></div>
                      </td>
                      <td>剩余{{ item.cnt }}次</td>
                    </tr>
                  </template>
                </v-data-table>
              </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions v-if="!isAuth">
          <!-- <v-spacer></v-spacer> -->
          <v-btn text @click="submitChanges" class="btn-submit mb-5">{{ '确认' }}</v-btn>
          <v-btn text @click="showModal = false" class="btn-cancel mb-5 me-5">返回</v-btn>
        </v-card-actions>

        <v-card-actions v-if="isAuth">
          <v-btn text @click="submitChanges" class="btn-submit mb-5 me-5">{{ '好的' }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> 

    <v-dialog
      v-model="deleteDialog"
      max-width="400px"
    >
      <v-card>
        <v-card-title class="mt-2 ml-3">删除确认</v-card-title>
          <v-card-text class="ml-1">
            <v-container>
              <v-row>
                <v-col cols="12" align="left">
                  <strong>姓名: {{ currentDeleteName }} </strong>
                </v-col>
                <v-col cols="12" align="left">
                  <strong>电话: {{ currentDeletePhone }} </strong>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="confirmDelete"
            class="btn-delete mb-5"
          >
            确认
          </v-btn>
          <v-btn
            text
            @click="deleteDialog = false"
            class="btn-cancel mb-5 me-5"
          >
            取消
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>

  <div class="text-center ma-2">
    <v-snackbar
      v-model="snackbar"
      :timeout="2000"
    >
      {{ toastText }}

      <template v-slot:actions>
        <v-btn
          color="primary"
          variant="text"
          @click="snackbar = false"
        >
          关闭提醒
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</div>
<AuthResultAudio ref="audioPlayer"></AuthResultAudio>

</template>

<script>
import { ref, nextTick, onBeforeUnmount,computed, watch } from 'vue';
import { Html5Qrcode } from 'html5-qrcode';
import AuthResultAudio from '../components/AuthResultAudio.vue';

export default {
  setup() {
    const snackbar = ref(false)
    const toastText = ref('')
    const audioPlayer = ref(null);

    // 剩余次数大于0时为true
    const isAuthSuccess = computed(() => memberInfo.value.success);

    // onMounted(() => {
    //   if (isRemainingCntPositive.value) {
    //     playSound(successSoundUrl);
    //   } else {
    //     playSound(failureSoundUrl);
    //   }
    // });

    // 密码输入框的状态
    const password = ref('');
    // 是否显示密码输入框
    const passwordDialog = ref(true);
    // 是否允许访问组件内容
    const hasAccess = ref(false);

    // 密码验证函数
    const verifyPassword = () => {

      // mock
      // hasAccess.value = true;
      // listMembers();
      // passwordDialog.value = false; // 关闭密码对话框

      // 读取密码文件的逻辑应该在服务器端完成，这里仅为示例
      // 假设正确的密码存储在服务器端的某个地方
      // 构建请求的 URL，并将密码作为查询参数附加
      const url = `${url_prefix}/verify?password=${password.value}`;

      // 发送 GET 请求进行验证
      fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(response => response.json())
      .then(data => {
        // 处理响应数据
        console.log('Success:', data);
        const res = data.data;
        if (res) {
          // 密码正确，赋予权限
          hasAccess.value = true;
          listMembers();
          localStorage.setItem('jwtToken', res);
          passwordDialog.value = false; // 关闭密码对话框
        } else {
          // 密码错误，重置密码输入框并提示错误
          alert('密码错误，请重试！');
        }
      })
      .catch((error) => {
        // 处理错误
        console.error('Error:', error);
        alert('网络错误,请重试！')
      });
    };

    // const url_prefix = 'http://127.0.0.1:8080/api'
     const url_prefix = 'https://doff.fun/api'

    // 会员验证记录的表头
    const authRecordHeaders = ref([
      { text: '扫码时间', value: 'authTime', align: 'start' },
      { text: '验证结果', value: 'success', align: 'start' },
      { text: '剩余次数', value: 'cnt', align: 'start' },
    ]);

    // 搜索框的绑定值
    const search = ref('');

    // 监听搜索框的变化
    watch(search, (newVal) => {
      // 每次搜索框变化时，过滤成员列表
      console.log(`搜索框的值已更改为: ${newVal}`);
    });

    // 计算属性：根据搜索框的值过滤成员列表
    const filteredMembers = computed(() => {
      return members.value.map(member => ({
        guestId: member.guestId,
        name: member.name,
        phone: member.phone,
        cnt: member.cnt,
        active: member.active
        // 添加更多字段以匹配 headers 中的定义
      })).filter(member => 
        member.phone.toString().includes(search.value) || 
        member.name.includes(search.value)
      );
    });

    const deleteDialog = ref(false);
    const currentDeleteId = ref(null);
    const currentDeleteName = ref('');
    const currentDeletePhone = ref('');

    const checkDetail = (id) => {
      listMembers();
      isAuth.value = false;
      const member = members.value.find(m => m.guestId === id);
      memberInfo.value.name = member.name;
      memberInfo.value.phone = member.phone;
      memberInfo.value.registerType = member.registerType;
      memberInfo.value.cnt = member.cnt;
      memberInfo.value.guestId = member.guestId;
      memberInfo.value.authRecords = member.authRecords;
      memberInfo.value.authRecords.sort((a, b) => b.order - a.order);
      memberInfo.value.active = member.active;
      memberInfo.value.registerTime = member.registerTime;
      console.log('checkDetail: ' + JSON.stringify(memberInfo.value))
      originCnt.value = member.cnt;
      showModal.value = true;

    
    }

    const increment = () => {
      memberInfo.value.cnt = Math.min(100, memberInfo.value.cnt + 10);
    }

    const decrement = () => {
      memberInfo.value.cnt = Math.max(0, memberInfo.value.cnt - 10);
    }

    const confirmDelete = () => {
      if (currentDeleteId.value) {
          // 使用模板字符串将 id 插入到 URL 中
          console.log('delete id = ' + currentDeleteId.value);
          const url = url_prefix + `/delete?guestId=${currentDeleteId.value}`;
          
          const token = localStorage.getItem('jwtToken');
          fetch(url, {
            method: 'GET', // 注意：对于 GET 请求，通常不需要设置 body
            headers: {
              'Authorization': `Bearer ${token}`
            }
          })
          .then(response => response.json())
          .then(data => {
            data = data.data
            console.log('服务器响应:', data);
            if (data) { // 假设服务器响应中包含 success 字段表示操作成功或失败
              snackbar.value = true;
              toastText.value = '删除成功'
              listMembers();
            } else {
              alert("删除失败！");
            }
          })
          .catch(error => {
            console.error('请求失败:', error);
          });

        deleteDialog.value = false;
        currentDeleteId.value = null; // 重置删除确认框的状态
        currentDeleteName.value = '';
        currentDeletePhone.value = '';
      }
    };

    const members = ref([]);
    const listMembers = () => {

    //   // mock
    //   members.value = [{
    //     guestId: 1,
    //     name: '测试',
    //     phone: '15522525720',
    //     registerType: 0,
    //     cnt: 1,
    //     active: true,
    //     authRecords: []
    //   },
    //   {
    //     guestId: 2,
    //     name: '李非凡',
    //     phone: '15522525720',
    //     registerType: 0,
    //     cnt: 1,
    //     active: true,
    //     authRecords: []
    //   }
    // ]

      const url = url_prefix + '/queryguests';
      const token = localStorage.getItem('jwtToken');
      fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          shopId: 0
        })
      })
      .then(response => response.json())
      .then(data => {
        console.log('服务器响应:', data);
        data = data.data;
        members.value = data.map(item => ({
          guestId: item.guestId,
          name: item.name,
          phone: item.phone + '',
          registerType: item.registerType,
          authRecords: item.authRecords,
          cnt: item.cnt,
          active: item.active,
          registerTime: item.registerTime
          // 只包含您需要的字段
        }));
      })
      .catch(error => {
        console.error('请求失败:', error);
      });
    }

    const isAuth = ref(false)
    const showModal = ref(false);
    const memberInfo = ref(
      { 
        guestId: 0,
        name: '', phone: '',
        registerType: 0,
        cnt: 0,
        active: false,
        authRecords: [],
        registerTime: ''
      });
    const originalName = ref(memberInfo.value.name);
    const originalPhone = ref(memberInfo.value.phone);
    const originCnt = ref(memberInfo.value.cnt);

    const cameraActive = ref(false);
    const memberHeaders = ref([
      // { text: 'ID', value: 'guestId', align: 'start', sortable: false },
      { text: '姓名', value: 'name', align: 'start' },
      { text: '手机号', value: 'phone', align: 'start' },
      { text: '可用次数', value: 'cnt', align: 'start' },
      // { text: '操作', value: 'actions', align: 'start' }, // 确保这个列名与操作按钮的插槽匹配
    ]);

    let html5QrCode;

    const toggleCamera = () => {
      if (cameraActive.value) {
        stopCamera();
        cameraActive.value = false;
      } else {
        cameraActive.value = true;
        startCamera();
      }
    };

    let lastScanTime = 0;
    let lastOpenId = '';
    const startCamera = async () => {
      await nextTick();
      const scannerElement = document.getElementById("scanner");
      if (scannerElement) {
        html5QrCode = new Html5Qrcode("scanner");

        const qrCodeSuccessCallback = (decodedText) => {
          // if (Date.now() - lastScanTime < 2000) {
          //   alert('2s后再扫！')
          //   return;
          // }
          // lastScanTime = Date.now();

          const params = new URLSearchParams(decodedText);
          const openId = params.get('openId');

          if (!openId) {
            alert('二维码无效！')
            return;
          }

          if (lastOpenId != '' && openId === lastOpenId && Date.now() - lastScanTime < 10000) {
            return;
          }
          lastOpenId = openId;
          lastScanTime = Date.now();

          const reqTime = params.get('reqTime');
          const token = localStorage.getItem('jwtToken');
          if (openId && reqTime) {
            const url = url_prefix + '/auth';
            fetch(url, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
              },
              body: JSON.stringify({ openId, reqTime })
            })
            .then(response => response.json())
            .then(data => {
              console.log('服务器响应:', data);
              if (data.code == 500) {
                alert('系统错误！')
              }
              data = data.data;
              if (data.qrCodeExpire) {
                alert('二维码已过期（2分钟有效）请10秒后重试！')
              } else {
                if (!data.exists) {
                  alert('该用户未注册！')
                } else if (!data.active) {
                  alert('用户' + data.name + '未激活！')
                } else {
                  memberInfo.value = {
                    name: data.name || '',
                    phone: data.phone || '',
                    active: data.active,
                    registerType: data.registerType,
                    guestId: data.guestId,
                    cnt: data.cnt,
                    authRecords: data.authRecords,
                    success: data.success
                  };
                  isAuth.value = true;
                  listMembers();
                  showModal.value = true;

                  if (audioPlayer.value) {
                    audioPlayer.value.play(memberInfo.value.success);
                  }
                }
              }
            })
            .catch(error => {
              console.error('请求失败:', error);
            });
          } else {
            alert('二维码信息不完整');
          }
        };

        const qrCodeErrorCallback = (errorMessage) => {
          console.error('二维码扫描失败:', errorMessage);
        };

        const config = {
          fps: 10,
          qrbox: { width: 250, height: 250 }
        };

        try {
          Html5Qrcode.getCameras()
            .then((devices) => {
              if (devices && devices.length) {
                lastScanTime = Date.now();
                lastOpenId = '';
                html5QrCode.start(
                  { facingMode: "environment" },
                  config,
                  qrCodeSuccessCallback,
                  qrCodeErrorCallback
                );
              }
            })
            .catch((err) => {
              alert(`启动摄像头失败: ${err.message}`);
              cameraActive.value = false;
            });
        } catch (err) {
          console.error("无法启动摄像头: ", err);
          alert(`启动摄像头失败: ${err.message}`);
        }
      } else {
        console.error('ID 为 "scanner" 的元素未找到');
      }
    };

    const stopCamera = () => {
      if (html5QrCode) {
        html5QrCode.stop().then(() => {
          console.log("二维码扫描已停止");
        }).catch(err => {
          console.error("停止二维码扫描时出错: ", err);
          alert(`停止二维码扫描时出错: ${err.message}`);
        });
      }
    };

    const toggleMemberStatus = (id) => {
      const member = members.value.find(m => m.guestId === id);
      console.log('toggleMemberStatus members: ' + members.value)
      const url = url_prefix + '/updatestatus';
      const token = localStorage.getItem('jwtToken');
      fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          guestId: id, 
          status: member.active ? 0 : 1
        })
      })
      .then(response => response.json())
      .then(data => {
        data = data.data;
        console.log('服务器响应:', data);
        if (data) {
          listMembers();
          snackbar.value = true;
          toastText.value = '修改成功'
        }
      })
      .catch(error => {
        console.error('请求失败:', error);
      });
    };

    const deleteMember = (id) => {
      const member = members.value.find(m => m.guestId === id);
      console.log('deleteMember:' + JSON.stringify(member));
      if (member) {
        currentDeleteId.value = member.guestId;
        currentDeleteName.value = member.name;
        currentDeletePhone.value = member.phone;
        deleteDialog.value = true; // 显示删除确认框
      }
    };

    const addMember = () => {
      // 默认添加会员为激活状态
      isAuth.value = false;
      memberInfo.value.name = '';
      memberInfo.value.phone = '';
      memberInfo.value.registerType = 1;
      console.log(isAuth.value)
      memberInfo.value.cnt =0;
      showModal.value = true;
    };

    onBeforeUnmount(() => {
      stopCamera();
    });

    const submitChanges = () => {
      listMembers();
      if (!isAuth.value) {
        const member = members.value.find(m => m.guestId == memberInfo.value.guestId);
        const hasChanges = memberInfo.value.name != member.name ||
          memberInfo.value.phone != member.phone ||
          memberInfo.value.cnt != member.cnt

        console.log('memberInfo.value.cnt: ' + memberInfo.value.cnt + '-----member.cnt: ' + member.cnt)

        if (hasChanges) {
          console.log('发送更新请求到后端', memberInfo.value);
          const shopId = memberInfo.value.shopId !== undefined && !isNaN(memberInfo.value.shopId) ? Math.max(0, memberInfo.value.shopId) : 0;
          const registerType = memberInfo.value.registerType !== undefined && !isNaN(memberInfo.value.registerType) ? Math.max(0, memberInfo.value.registerType) : 0;
          const url = url_prefix +  '/updateOnDuplicateKey'
          const token = localStorage.getItem('jwtToken');
          fetch(url, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                  guestId: memberInfo.value.guestId, 
                  name: memberInfo.value.name,
                  phone: memberInfo.value.phone,
                  cnt: memberInfo.value.cnt,
                  status: memberInfo.value.active ? 1 : 0,
                  registerType: Math.max(registerType, 0),
                  shopId: Math.max(0, shopId)
                })
              })
              .then(response => response.json())
              .then(data => {
                data = data.data;
                console.log('服务器响应:', data);
                if (data) {
                  listMembers();
                  showModal.value = false;
                  // alert('修改成功')
                  snackbar.value = true;
                  toastText.value = '修改成功'
                } else {
                  // alert('网络错误！')
                  snackbar.value = true;
                  toastText.value = '网络错误'
                  showModal.value = false;
                }
              })
              .catch(error => {
                console.error('请求失败:', error);
                showModal.value = false;
              });
        }
      }
      showModal.value = false;
      memberInfo.value.phone = '';
      memberInfo.value.name = '';
    };

    // 用于控制自动刷新的定时器
    const intervalId = ref(null);

    // 定义一个函数来启动自动刷新
    const startAutoRefresh = () => {
      // 清除已有的定时器
      if (intervalId.value) {
        clearInterval(intervalId.value);
      }
      // 设置新的定时器，每2秒调用一次listMembers函数
      intervalId.value = setInterval(() => {
        listMembers();
      }, 2000);
    };

    // 在组件卸载前清除定时器
    onBeforeUnmount(() => {
      stopAutoRefresh();
    });

    // 监听 hasAccess 的变化，当用户输入密码正确后开始自动刷新
    watch(hasAccess, (newVal) => {
      if (newVal) {
        startAutoRefresh();
      } else {
        stopAutoRefresh();
      }
    });

    // 定义一个函数来停止自动刷新
    const stopAutoRefresh = () => {
      if (intervalId.value) {
        clearInterval(intervalId.value);
        intervalId.value = null;
      }
    };

    return {
      startAutoRefresh,
      stopAutoRefresh,
      members,
      isAuth,
      showModal,
      memberInfo,
      originalName,
      originalPhone,
      originCnt,
      cameraActive,
      memberHeaders,
      toggleCamera,
      toggleMemberStatus,
      deleteMember,
      addMember,
      submitChanges,
      search,
      filteredMembers,
      confirmDelete,
      deleteDialog,
      currentDeleteId,
      currentDeleteName,
      currentDeletePhone,
      checkDetail,
      password,
      passwordDialog,
      hasAccess,
      verifyPassword,
      listMembers,
      increment,
      decrement,
      isRemainingCntPositive: isAuthSuccess,
      audioPlayer,
      toastText,
      snackbar,
      authRecordHeaders,
    };
  },
  components: {
    AuthResultAudio
  }
};
</script>

<style scoped>
.v-dialog {
  max-height: 90vh; /* 限制弹出框的最大高度为视口高度的90% */
  display: flex;
  flex-direction: column;
}

/* 弹出框内容的样式 */
.v-dialog .v-card {
  display: flex;
  flex-direction: column;
  flex-grow: 1; /* 使卡片填充剩余空间 */
}

/* 弹出框内容文本的样式 */
.v-dialog .v-card-text {
  flex-grow: 1; /* 使文本区域填充剩余空间 */
  padding: 24px; /* 根据需要调整内边距 */
  /* overflow: hidden; */
}

/* 弹出框按钮的样式 */
.v-dialog .v-card-actions {
  padding: 8px; /* 根据需要调整内边距 */
}

.full-height-card {
  height: 100%;
}

.icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon {
  width: 100px; /* 根据需要调整大小 */
  height: 100px; /* 根据需要调整大小 */
  background-size: cover;
  background-position: center;
  border-radius: 50%;
  color: white;
  font-size: 24px;
  line-height: 48px;
  text-align: center;
  font-weight: bold;
}

.checkmark {
  background-image: url('~@/assets/success.svg');
}

.small-checkmark {
  background-image: url('~@/assets/success.svg');
  transform: scale(0.2);
}

.small-crossmark {
  background-image: url('~@/assets/crossmark.svg');
  transform: scale(0.2);
}

.crossmark {
  background-image: url('~@/assets/crossmark.svg');
}

.bg-green {
  background-color: green;
}

.bg-red {
  background-color: red;
}


.camera-container {
  position: relative;
}

.qr-scanner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 2px solid white; /* 扫描框 */
  pointer-events: none; /* 防止边框覆盖视频 */
}

.btn-submit {
  /* border: 2px solid #4caf50; 绿色边框 */
  color: rgb(255,255,255); /* 文字颜色 */
  background-color: rgb(0, 102, 204);
}

.btn-cancel {
  /* border: 2px solid #f44336; 红色边框 */
  color: black; /* 文字颜色 */
  background-color: lightgrey;
}

.btn-delete {
  color: rgb(255,255,255); /* 文字颜色 */
  background-color: red;
}

.text-field-custom{
  /* 调整输入框内部的字体大小 */
  font-size: 22px; /* 根据您的需要设置大小 */
}

/* 正常状态下的标签样式 */
#member-switch .v-label {
  color: #000; /* 黑色文字 */
  font-weight: bold; /* 加粗文字 */
}

/* 开启状态下的标签样式 */
#member-switch.v-input--switch .v-label {
  color: green; /* 绿色文字 */
}

/* 关闭状态下的标签样式 */
#member-switch.v-input--switch input[type="checkbox"]:not(:checked) + .v-label {
  color: red; /* 红色文字 */
}

.auth-table {
  max-height: 100px; /* 您可以根据需要设置这个值 */
  overflow-y: auto;
}

</style>